@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

body {
  background: #000;
}

body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-thumb {
  background-color: #1a1919;
  border-radius: 5px;
}
body::-webkit-scrollbar-track {
  background-color: grey;
  border-radius: 5px;
}

h1,
h4,
p,
a {
  color: #fff;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  border-radius: 15px;
  font-size: 1rem;
  text-transform: uppercase;
  background: rgb(248, 217, 15);
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btn-light {
  /* background: rgba(255, 255, 255, 0.2); */
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

.NotFoundPage {
  text-align: center;
}

.NotFoundPage div{
    width:500px;
    height:200px;
    position:absolute;
    left:50%;
    top:50%;
    margin-left:-250px;
    margin-top:-100px;
}

.NotFoundPage h2{
  color: #fff;
}

.NotFoundPage button {
  margin-top: 5%;
}

.centered {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}